import { isBrowser } from '../utils/isBrowser';
import React from 'react';

const ReviewPage = () => {
  if (isBrowser) {
    window.location.href = 'https://search.google.com/local/writereview?placeid=ChIJ8ThsnPJLHRURH8Dsth0upmw';
  }
  return <h1 style={{ direction: 'rtl' }}></h1>;
};

export default ReviewPage;
